.loginBK > img {
  height: 83vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.LoginForm {
  position: absolute;
  top: calc(50vh - 15em);
  right: calc(50vw - 10em);
  background-color: #f7f7f7 !important;
  padding: 2em;
  border-radius: 5px;
  box-shadow: 11px 10px 20px -4px rgba(189, 189, 189, 0.98);
  -webkit-box-shadow: 11px 10px 20px -4px rgba(189, 189, 189, 0.98);
  -moz-box-shadow: 11px 10px 20px -4px rgba(189, 189, 189, 0.98);
  border: solid 1px lightgrey;
}
